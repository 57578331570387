<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Обновить Оператора</strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateOperator()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                      label="Имя оператора"
                      v-model="form.operator_name"
                      required
              />
                <CInput
                        label="Логин оператора"
                        v-model="form.operator_login"
                        required
                />
              <CInput
                      type="tel"
                      label="Телефон оператора"
                      v-model="form.operator_phone"
                      required
              />
              <CInput
                      type="password"
                      label="Пароль"
                      v-model="form.password"
              />
              <div class="mb-3">
                <label class="typo__label">ГРСы</label>
                <multiselect
                    v-model="operator_grses.value"
                    placeholder="Поиск"
                    label="name"
                    track-by="code"
                    :options="operator_grses.options"
                    :multiple="true"
                    :taggable="true"
                >
                </multiselect>
              </div>

              <div class="mb-5">
                <cSelect :options="roles"
                         :value.sync="form.operator_role"
                         placeholder="Роль"
                         label="Роль">
                </cSelect>
              </div>
              <CButton type="submit" size="sm" color="primary">Обновить</CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
  import axios from 'axios';
  import Multiselect from 'vue-multiselect';

  function getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
              c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
          }
      }
      return "";
  }

  export default {
    name: 'Operators',
    data: function() {
      return {
        activeOperatorId: 0,
        visibleLiveDemo: false,
        apiDatas: [],
        grsDatas: [],
        operatorDatas: [],
        roles: [],
        operator_grses: {
            value: [

            ],
            options: [

            ]
        },
        tableFields: ['id', 'name', 'phone'],
        form: {
          action: 'update_operator',
          operator_name: "",
          operator_login: "",
          operator_phone: "",
          operator_grses: "",
          operator_role: "",
          password: ""
        },
      }
    },
    methods: {
      getDataFromApi () {
        axios
          .get(`${axios.defaults.baseURL}?action=get_operators`)
          .then(response => (this.apiDatas = response.data))
      },
      updateOperator () {
        //TODO: `После добавления запрашивать только последнего добавленного`
        let bodyFormData = new FormData();

        let grses = "";

        if (this.operator_grses.value.length > 0) {
            this.operator_grses.value.forEach((el, index) => {
                grses += el.id;
                if (index < this.operator_grses.value.length - 1) {
                    grses += ",";
                }
            })
        }

        bodyFormData.append('action', this.form.action);
        bodyFormData.append('id', this.activeOperatorId);
        bodyFormData.append('name', this.form.operator_name);
        bodyFormData.append('login', this.form.operator_login);
        bodyFormData.append('phone', this.form.operator_phone);
        bodyFormData.append('password', this.form.password);
          bodyFormData.append('role', this.form.operator_role);
        bodyFormData.append('grses', grses);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then((res) => {
              this.$router.push({path: `/operators`})
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      removeLine (item) {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "remove_operator");
        bodyFormData.append('id', item.id);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then(() => {
            //Perform Success Action
              this.$router.push({path: `/operators`})
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
    },
    created() {
        this.$watch(
            () => this.$route.params
        )
    },
    mounted() {
        if (this.$route.meta.oid === undefined) {
            this.activeOperatorId = getCookie('activeOperatorId');
        } else {
            document.cookie = `activeOperatorId=${this.$route.meta.oid}`
            this.activeOperatorId = this.$route.meta.oid;
        }
      axios
        .get(`${axios.defaults.baseURL}?action=get_operator&id=${this.activeOperatorId}`)
        .then((response) => {
            this.form.operator_name = response.data[0].name;
            this.form.operator_login = response.data[0].login;
            this.form.operator_phone = response.data[0].phone;
            this.form.operator_grses = response.data[0].grses;

            console.log(response.data[0])

            this.operator_grses.value = response.data[0].grses;
            this.form.operator_role = response.data[0].role;
        });
        axios
            .get(`${axios.defaults.baseURL}?action=get_allgrses&no_operators=1`)
            .then((response) => {
                this.operator_grses.options = response.data;
            });
        axios
          .get(`${axios.defaults.baseURL}?action=get_roles`)
          .then((response) => {
              response.data.forEach((e) => {
                  this.roles.push({
                      value: e.id,
                      label: e.name
                  })
              });
          })
    },
    components: {
        Multiselect,
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
  .multiselect__tag {
    background-color: var(--light);
    color: var(--dark);
  }
  .multiselect__tag-icon:after {
    color: var(--dark);
  }
  .multiselect__tag-icon {
    line-height: 20px;
  }
  .multiselect__tag-icon:hover {
    background-color: var(--dark);
    color: white;
  }
  .multiselect__option--highlight {
    background-color: var(--secondary);
    color: var(--dark);
  }
  .multiselect__option--highlight:after {
    display: none;
  }
</style>
